<template>
  <div class="modal__dialog">
    <div class="modal__header">
      <div class="modal__close">
        <img
          src="../../../assets/img/ico/ico-close.svg"
          alt="Cerrar"
          @click="$emit('close')"
        />
      </div>
    </div>
    <div class="modal__content">
      <div class="card-success">
        <div class="card-success__ico">
          <img
            src="../../../assets/img/ico/ico-cover-favicon-success.svg"
            alt="¡Felicidades!"
          />
        </div>
        <h3 class="card-success__header">{{ $t("freeAdd.extensionLoginTitle") }}</h3>
        <div class="card-success__content" v-html="$t('freeAdd.extensionLoginSubtitle')">
        </div>
        <a
          class="button button--primary"
          href='/login' target='_blank'
          >{{ $t('freeAdd.extensionLoginButton') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import bbButton from '../../../components/buttons/bbButton.vue';

export default {
  components: { bbButton },
  name: "PleaseLoginExtension",
};
</script>
